/* eslint-disable no-console */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  TopChallengesWrapper,
  TopCardEngagement,
  OverviewWrapper,
} from "./styles";
import { ImageUrl } from "../../utils/constants";
import { connect } from "react-redux";
import SkeletonLoder from "../common/skeletonLoder";
import RecommendedEvents from "./RecommendedEvents";
import { GetRecommendedEventData } from "../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import { fetchApi } from "../../utils/methods";

const Overview = ({ EngagementData, EngagementDataLoading, history, companyId }) => {
  const [RecommendedEventData, setRecommendedEventData] = React.useState([]);
  const [RecommendedEventDataLoading, setRecommendedEventDataLoading] = React.useState(false);

  useEffect(() => {
    !RecommendedEventData.length && GetRecommendedEvents(companyId);
  }, []);

  const GetRecommendedEvents = async (companyId) => {
    setRecommendedEventDataLoading(true);
    const apiUrl = GetRecommendedEventData + (companyId && "/" + companyId);
    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setRecommendedEventDataLoading(false);
        setRecommendedEventData(res?.data);
      } else {
        toast.error(res.error);
        setRecommendedEventDataLoading(false);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const showEngagementEmployeeDetail = () => {
    const EngagementEmployeeData = [
      {
        title: "Events",
        titleNew: "Engagement",
        value: "1",
        img: "/images/admin-events/engagement.svg",
        text: "per user",
        color: "rgba(133, 192, 234, 1)",
        total: EngagementData?.engagement_total,
        imageBackground: "rgba(133, 192, 234, 0.15)",
        subText: "Total Events",
        info: "We display wins in health where the average score exceeds Mark of 75%.",
        average: EngagementData?.engagement_average + " Event" + (EngagementData?.engagement_average > 1 ? "s" : ""),
      },
      {
        title: "Live Events",
        titleNew: "Attended",
        value: "2",
        img: "/images/admin-events/live.svg",
        text: "Per User",
        color: "rgba(220, 148, 181, 1)",
        total: EngagementData?.live_events_total,
        average: EngagementData?.live_events_average + " Live Event" + (EngagementData?.live_events_average > 1 ? "s" : ""),
        imageBackground: "rgba(220, 148, 181, 0.15)",
        subText: "Events Attended",
        info: "We display wins in health where the average score exceeds Mark of 75%.",
      },
      {
        title: "On-Demand",
        titleNew: "Lunch and Learns",
        value: "3",
        img: "/images/admin-events/ondemand.svg",
        text: "Per User",
        color: "rgba(118, 171, 120, 1)",
        total: EngagementData?.on_demand_events_total,
        average:
          EngagementData?.on_demand_events_average + " Lunch and Learn" + (EngagementData?.on_demand_events_average > 1 ? "s" : ""),
        imageBackground: "rgba(118, 171, 120, 0.15)",
        subText: "Watched",
        info: "We display wins in health where the average score exceeds Mark of 75%.",
      },
      {
        title: "Total Company",
        titleNew: "Events Created",
        value: "4",
        img: "/images/admin-events/company.svg",
        text: "Per Year",
        color: "rgba(175, 135, 193, 1)",
        total: EngagementData?.company_events_total,
        average: EngagementData?.company_events_average + " Event" + (EngagementData?.company_events_average > 1 ? "s" : ""),
        imageBackground: "rgba(175, 135, 193, 0.15)",
        subText: "Total Events",
        info: "We display wins in health where the average score exceeds Mark of 75%.",
      },
    ];
    return EngagementDataLoading ? (
      <React.Fragment>
        {" "}
        {EngagementEmployeeData.map((details, index) => (
          <SkeletonLoder
            width={"288px"}
            height={"180px"}
            key={index}
          ></SkeletonLoder>
        ))}
      </React.Fragment>
    ) : (
      EngagementEmployeeData.map((details, index) => (
        <TopCardEngagement
          key={index}
          background={details.imageBackground}
          color={details.color}
          style={{ padding: "12px" }}
        >
          <div className="first-child">
            <div className="image">
              <img
                src={ImageUrl + details.img}
                alt={"engagement-employee"}
                onError={fallBack}
              />
            </div>
            <div className="title">
              {details.title}
              <br />
              {details.titleNew}
            </div>
            <div className="infoIcon">
              {/* {this.infoDetails(details.info)} */}
              {/* <img src={ImageUrl + "/social-feed/info.png"} alt={"engagement-employee"} /> */}
            </div>
          </div>
          <div className="challengeData">
            <span className="value">{details?.total}</span>
            <span className="valueText">{details.subText}</span>
          </div>

          <div
            className="borderCard"
            style={{ width: "100%", margin: "20px 0px" }}
          />

          <div className="last-child">
            <span className="participateText">
              {index === 3 ? "Target" : "Average"}
            </span>
            <span className="totalParticipant">{details.average}</span>
            <span className="participateText">{details.text}</span>
          </div>
        </TopCardEngagement>
      ))
    );
  };

  const fallBack = (e) => {
    e.target.src = "/public/images/default.png";
  };

  return (
    <OverviewWrapper>
      <TopChallengesWrapper>
        {showEngagementEmployeeDetail()}
      </TopChallengesWrapper>

      {RecommendedEventData?.length > 0 ? (
        <RecommendedEvents
          RecommendedEventData={RecommendedEventData}
          margin="60px"
          isActivity="1"
          history={history}
          RecommendedEventDataLoading={RecommendedEventDataLoading}
        />
      ) : null}
    </OverviewWrapper>
  );
};

Overview.propTypes = {
  EngagementData: PropTypes.object.isRequired,
  companyId: PropTypes.number,
  selectedYearInfo: PropTypes.number,
  EngagementDataLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export default connect(null, null)(Overview);
